<template>
	<el-dialog class="dialog" :title="title" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<div class="flex align-center jc-between titleBox">
				<div>PC设置（建议尺寸1920X823）</div>
				<el-button type="primary" @click="addList('pc')">添加一个</el-button>
			</div>
			<div class="dlalog-body-center">
				<el-form :ref="'pcform' + i" :rules="rules" :model="item" label-width="120px" v-for="(item, i) in pcBannerEos" :key="'pcform' + i">
					<el-row :gutter="20">
						<el-col :span="5">
							<el-upload
								class="avatar-uploader"
								:action="baseURL + '/base-api/file/upload'"
								:show-file-list="false"
								:before-upload="BeforeUpload"
								:on-success="
									$event => {
										handleAvatarSuccess($event, item);
									}
								"
							>
								<el-image v-if="item.image" :src="uploadUrl + item.image" fit="fill" class="avatar"></el-image>
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-col>
						<el-col :span="17">
							<el-row>
								<el-col :span="12">
									<el-form-item label="广告链接：" prop="link"><el-input v-model="item.link"></el-input></el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="广告标题：" prop="title"><el-input v-model="item.title"></el-input></el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="18">
									<el-form-item label="广告描述：" prop="description"><el-input v-model="item.description"></el-input></el-form-item>
								</el-col>
								<el-col :span="6">
									<el-form-item label="排序：" prop="seq"><el-input v-model="item.seq"></el-input></el-form-item>
								</el-col>
							</el-row>
						</el-col>
						<el-col :span="2" class="rightBox">
							<el-row>
								<el-col :span="12"><el-button type="primary" plain @click="submit('pcform' + i, item, 'pc', item.seq)">保存</el-button></el-col>
								<!-- <el-col :span="12"><div class="text" @click="itemUp(pcBannerEos, i, 'pc')" v-if="i != 0">上移</div></el-col> -->
							</el-row>
							<el-row>
								<el-col :span="12"><div class="text" @click="deleteItem('pc', item, i)">删除</div></el-col>
								<!-- <el-col :span="12"><div class="text" @click="itemDown(pcBannerEos, i, 'pc')" v-if="i != pcBannerEos.length - 1">下移</div></el-col> -->
							</el-row>
						</el-col>
					</el-row>
				</el-form>
			</div>

			<div class="flex align-center jc-between titleBox">
				<div>APP设置（建议尺寸718 X 308）</div>
				<el-button type="primary" @click="addList('app')">添加一个</el-button>
			</div>
			<div class="dlalog-body-center">
				<el-form :ref="'Appform' + i" :rules="rules" :model="item" label-width="120px" v-for="(item, i) in appBannerEos" :key="'Appform' + i">
					<el-row :gutter="20">
						<el-col :span="5">
							<el-upload
								class="avatar-uploader"
								:action="baseURL + '/base-api/file/upload'"
								:show-file-list="false"
								:before-upload="BeforeUpload"
								:on-success="
									$event => {
										handleAvatarSuccess($event, item);
									}
								"
							>
								<el-image v-if="item.image" :src="uploadUrl + item.image" fit="fill" class="avatar"></el-image>
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-col>
						<el-col :span="17">
							<el-row>
								<el-col :span="12">
									<el-form-item label="广告链接：" prop="link"><el-input v-model="item.link"></el-input></el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="广告标题：" prop="title"><el-input v-model="item.title"></el-input></el-form-item>
								</el-col>
							</el-row>
							<el-row>
								<el-col :span="18">
									<el-form-item label="广告描述：" prop="description"><el-input v-model="item.description"></el-input></el-form-item>
								</el-col>
								<el-col :span="6">
									<el-form-item label="排序：" prop="seq"><el-input v-model="item.seq"></el-input></el-form-item>
								</el-col>
							</el-row>
						</el-col>
						<el-col :span="2" class="rightBox">
							<el-row>
								<el-col :span="12"><el-button type="primary" plain @click="submit('Appform' + i, item, 'app', item.seq)">保存</el-button></el-col>
							</el-row>
							<el-row>
								<el-col :span="12"><div class="text" @click="deleteItem('app', item, i)">删除</div></el-col>
								<!-- <el-col :span="12"><div class="text" @click="itemDown(appBannerEos, i, 'app')" v-if="i != appBannerEos.length - 1">下移</div></el-col> -->
							</el-row>
						</el-col>
					</el-row>
				</el-form>
			</div>

			<div slot="footer" class="dialog-footer"><el-button @click="close()">关闭</el-button></div>
		</div>
	</el-dialog>
</template>
<script>
import { dialogMixin } from '@m/dialogMixin';
import configApi from '@config/configApi';
import UpDownFunction from '@assets/js/upDown';
export default {
	mixins: [dialogMixin],
	data() {
		return {
			title: '',
			loading: false,
			formData: {},
			pcBannerEos: {},
			appBannerEos: {},
			rules: {
				link: {
					required: true,
					message: '此项必填',
					trigger: 'blur'
				},
				title: {
					required: true,
					message: '此项必填',
					trigger: 'blur'
				},
				description: {
					required: true,
					message: '此项必填',
					trigger: 'blur'
				},
				seq: {
					required: true,
					message: '此项必填',
					trigger: 'blur'
				}
			},
			uploadUrl: configApi.photoURL,
			newFile: new FormData()
		};
	},
	methods: {
		//上传前校验
		BeforeUpload(file) {
			const isLt2M = file.size / 1024 / 1024 < 10;
			const fileType = file.name.substring(file.name.lastIndexOf('.'));
			if (fileType != '.jpg' && fileType != '.JPG' && fileType != '.PNG' && fileType != '.png') {
				this.$message.error('上传头像图片只能是 JPG,PNG 格式!');
			} else if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 10MB!');
			} else {
				this.currentFileName = file.name;
				if (file) {
					if (this.newFile.has('file')) {
						this.newFile.delete('file');
					}
					this.newFile.append('file', file);
					return true;
				} else {
					return false;
				}
			}
			return false;
		},
		//手动上传
		handleAvatarSuccess(res, item) {
			item.image = res.data;
		},
		//打开弹窗
		open() {
			this.formData = {};
			this.get();
			// this.$nextTick(() => {
			// 	this.$refs.form.clearValidate();
			// });
		},
		//获取数据
		get() {
			if (this.dialogObj.type == 0 || this.dialogObj.type == 1) {
				this.title = '编辑';
				this.$get('/shop-api/mgmt/banner/query?bannerType=' + this.dialogObj.id).then(res => {
					if (res.code == 1000) {
						this.pcBannerEos = res.data.pcBannerEos;
						this.appBannerEos = res.data.appBannerEos;
					} else {
						this.$message.error(res.msg);
					}
				});
			} else if (this.dialogObj.type == 2) {
				this.title = '添加';
			}
		},
		//提交表单
		submit(type, data, os, seq) {
			let tstr = type.toString();
			if (os == 'pc') {
				data.os = 1;
			} else {
				data.os = 2;
			}
			data.seq = seq;
			this.$refs['Appform0'][0].validate(valid => {
				if (valid) {
					this.loading = true;
					if (this.dialogObj.type == 1) {
						this.$postJson('/shop-api/mgmt/banner/save', data).then(res => {
							this.loading = false;
							if (res.code != 1000) {
								this.$message.error(res.msg);
								return;
							}
							this.$message.success('当前行保存成功');
							// this.get();
							this.$parent.list();
							// this.close();
						});
					} else if (this.dialogObj.type == 2) {
					}
				} else {
					return false;
				}
			});
		},
		//关闭弹窗
		close() {
			this.visiable = false;
		},
		//增加
		addList(e) {
			let obj = {
				description: '',
				image: '',
				title: '',
				link: '',
				type: this.dialogObj.id
			};
			if (e == 'pc') {
				this.pcBannerEos.push(obj);
			} else {
				this.appBannerEos.push(obj);
			}
		},
		//删除
		deleteItem(e, item, i) {
			if (item.id) {
				this.$post('/shop-api/mgmt/banner/delete?id=' + item.id).then(res => {
					if (res.code != 1000) {
						this.$message.error(res.msg);
						return;
					}
					this.$message.success(res.msg);
					this.get();
				});
			} else {
				if (e == 'pc') {
					this.pcBannerEos.splice(i, 1);
				} else {
					this.appBannerEos.splice(i, 1);
				}
			}
		},
		//上移
		itemUp(arr, i, type) {
			const UpDownFunctions = new UpDownFunction(arr, i);
			UpDownFunctions.upData();
			let formtype;
			if (type == 'pc') {
				formtype = 'pcform' + i;
			} else {
				formtype = 'Appform' + i;
			}
			this.submit(formtype, arr[i], type, i);
		},
		itemDown(arr, i, type) {
			const UpDownFunctions = new UpDownFunction(arr, i);
			UpDownFunctions.downData();
			let formtype;
			if (type == 'pc') {
				formtype = 'pcform' + i;
			} else {
				formtype = 'Appform' + i;
			}
			this.submit(formtype, arr[i], type, i);
		}
		//下移
	}
};
</script>
<style lang="scss" scoped>
.dialog {
	::v-deep.cascader {
		display: block;
	}
}

::v-deep.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
::v-deep.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 200px;
	height: 78px;
	line-height: 78px;
	text-align: center;
}
.avatar {
	max-width: 200px;
	height: 78px;
	display: block;
}

.rightBox {
	text-align: center;
	.text {
		margin-top: 10px;
		line-height: 40px;
		font-size: 14px;
		cursor: pointer;
		color: #1bcaa7;
	}
	.text:hover {
		color: #ff0000;
	}
}
.titleBox {
	padding: 10px 20px;
	background-color: #efefef;
	align-items: center;
}
.dlalog-body-center {
	height: 30vh;
	overflow: auto;
}
</style>
