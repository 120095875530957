/**
 * UpDownFunction: 上移和下移
 * @params {*} arr: 数组
 * @params {*} index:需要处理的index
 * upData: 这是上移
 * downData： 这是下移
 */
export default class UpDownFunction {
  constructor(arr = [], index) {
    this.arr = arr
    this.index = index
  }

  swapItems(arr, index1, index2) {
    arr[index1] = arr.splice(index2, 1, arr[index1])[0]
    return arr
  }

  upData() {
    let newArr = []
    if (this.arr.length > 1 && this.index !== 0) {
      newArr = this.swapItems(this.arr, this.index, this.index - 1)
    }
    return newArr
  }
  downData() {
    let newArr = []
    if (this.arr.length > 1 && this.index !== (this.arr.length - 1)) {
      newArr = this.swapItems(this.arr, this.index, this.index + 1)
    }
    return newArr
  }
}
